<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <v-row>
      <v-col>
        <!-- ---------------------------------------------------------------------------------------------------------- ->
        <! all item fields                                                                                              ->
        <!- ------------------------------------------------------------------------------------------------------------->
        <v-row>
          <!-- 

			// Tabs 
			DeliveryInfos []
			Details []

          -->

          <!-- -------------------------------------------------------------------------------------------------------- ->
          <! order line                                                                                                 ->
          <!- ----------------------------------------------------------------------------------------------------------->
          <v-col lg="4" md="6" cols="12">
            <v-card outlined>
              <v-card-title>{{ getTitle() }}</v-card-title>

              <v-row no-gutters>
                <v-col class="mx-4 px-0">
                  <w-text-field
                    :text="item.parentOrder.orderReference"
                    label="parent order"
                    :back-to="orderReadPath"
                    :back-to-ref="item.parentOrder.orderReference"
                  />
                  <w-text-field
                    :text="item.parentOrder.orderClientReference"
                    label="parent order"
                    :back-to="orderReadPath"
                    :back-to-ref="item.parentOrder.orderReference"
                  />
                  <w-text-field :text="item.requestedProvider" label="requested provider" />
                  <w-num-field :num="item.orderedQuantity" label="ordered quantity" />
                </v-col>

                <v-divider vertical class="mx-0"></v-divider>

                <v-col class="mx-4">
                  <w-text-field :text="item.techStatusString" label="tech status" />
                  <w-text-field :text="item.orderProviderReference" label="order provider ref" />
                  <w-text-field :text="item.confirmedProvider" label="confirmed provider" />
                  <w-num-field :num="item.confirmedQuantity" label="confirmed quantity" />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="9" class="mx-4 px-3 mt-0 pt-0">
                  <w-textarea :text="item.comment" label="comment" />
                </v-col>
                <v-col cols="2">
                  <v-row>
                    <span>More</span>
                  </v-row>
                  <v-row>
                    <v-btn icon @click="showMoreLine = !showMoreLine">
                      <v-icon>{{ showMoreLine ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>

              <v-row v-show="showMoreLine" no-gutters>
                <v-col class="ml-4 px-0">
                  <w-text-field :text="item.offerCode" label="offer code" />
                </v-col>
                <v-col class="mx-4 px-0">
                  <w-text-field :text="item.offerID" label="offer ID" />
                </v-col>
                <v-col class="mr-4 px-0">
                  <w-text-field :text="item.originString" label="origin" />
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <!-- -------------------------------------------------------------------------------------------------------- ->
          <! delivery                                                                                                   ->
          <!- ----------------------------------------------------------------------------------------------------------->
          <v-col lg="4" md="6" cols="12">
            <v-card outlined>
              <v-card-title>Delivery</v-card-title>
              <v-row no-gutters>
                <v-col class="mx-4">
                  <v-row no-gutters>
                    <v-col>
                      <w-text-field :text="item.expectedDeliveryString" label="expected delivery" />
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <w-checkbox :text="item.forceCompleteDelivery" label="force complete"></w-checkbox>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <w-checkbox :text="item.isNormalDelivery" label="normal"></w-checkbox>
                      <w-checkbox :text="item.isExpressDelivery" label="express"></w-checkbox>
                    </v-col>
                    <v-col>
                      <w-checkbox :text="item.isProxi" label="proxi"></w-checkbox>
                      <w-checkbox :text="item.isCourierDelivery" label="courier"></w-checkbox>
                      <w-checkbox :text="item.isPickUp" label="pick up"></w-checkbox>
                    </v-col>
                  </v-row>
                </v-col>

                <v-divider vertical class="mx-0"></v-divider>

                <v-col class="mx-4">
                  <w-text-field :text="item.confirmedDeliveryMode" label="delivery mode" />
                  <w-text-field :text="getDeliveryDate()" label="delivery date" />
                  <w-text-field :text="item.delivererLabel" label="deliverer label" />
                  <w-text-field :text="item.delivererCode" label="deliverer code" />
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <!-- -------------------------------------------------------------------------------------------------------- ->
          <! product                                                                                                    ->
          <!- ----------------------------------------------------------------------------------------------------------->
          <v-col lg="4" md="6" cols="12">
            <v-card outlined>
              <v-card-title>Product</v-card-title>

              <v-row no-gutters>
                <v-col class="mx-4">
                  <w-text-field :text.sync="item.productEANReference" label="EAN" editable />
                  <w-text-field :text="getManufRef()" label="manufacturer ref." />
                  <w-text-field :text="item.productCustomReference" label="custom ref." />
                  <w-text-field :text="item.productProviderReference" label="provider ref." />
                  <w-checkbox
                    v-if="item.isEquivalent"
                    :value="item.isEquivalent"
                    label="equivalent"
                  />
                </v-col>

                <v-divider vertical class="mx-0"></v-divider>

                <v-col class="mx-4">
                  <w-num-field
                    :num.sync="item.totalRawPriceBeforeTax"
                    label="total raw price w/o tax"
                  />
                  <w-num-field :num="item.totalPriceBeforeTax" label="total price w/o tax" />
                  <w-num-field :num="item.unitRawPriceBeforeTax" label="unit raw price w/o tax" />
                  <w-num-field :num="item.unitPriceBeforeTax" label="unit price w/o tax" />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="10" class="mx-4 px-3 mt-0 pt-0">
                  <w-text-field :text="getProductLabel()" label="label" />
                </v-col>
                <v-col cols="1">
                  <v-row>
                    <v-btn icon @click="showMoreLine = !showMoreLine">
                      <v-icon>{{ showMoreLine ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>

              <v-row v-show="showMoreLine" no-gutters>
                <v-col class="ml-4 px-0">
                  <w-num-field :num="item.totalEcoTax" label="total eco tax" />
                  <w-num-field :num="item.unitEcoTax" label="unit eco tax" />
                </v-col>
                <v-col class="ml-4 px-0">
                  <w-num-field :num="item.totalExpressDeliveryCost" label="total express cost" />
                  <w-num-field :num="item.unitExpressDeliveryCost" label="unit express cost" />
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <!-- -------------------------------------------------------------------------------------------------------- ->
          <! / all item fields                                                                                          ->
          <!- ----------------------------------------------------------------------------------------------------------->
        </v-row>
        <!-- ---------------------------------------------------------------------------------------------------------- ->
        <! Items                                                                                                        ->
        <!- ------------------------------------------------------------------------------------------------------------->
        <!-- <v-row>
          <v-col>
            <v-card class="elevation-6">
              <w-entity-list
                :columns="columns"
                :items="_item_.items"
                :read-path="readItemPath"
                read-ref="itemReference"
                title="Items"
              />
            </v-card>
          </v-col>
        </v-row>-->
        <!-- --------------------------------------------------------------------------------------------------------- -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  VBtn,
  VCard,
  VCardTitle,
  VCol,
  VContainer,
  VDivider,
  VIcon,
  VRow,
  //   VSpacer,
} from "vuetify/lib";
import WCheckbox from "../../components/external/modules/shared/views/WCheckbox.vue";
// import WEntityList from "../../components/external/modules/shared/views/WEntityList.vue";
import WNumField from "../../components/external/modules/shared/views/WNumField";
import WTextarea from "../../components/external/modules/shared/views/WTextarea";
import WTextField from "../../components/external/modules/shared/views/WTextField";
import api from "../../components/external/modules/shared/utils/api";
import path from "../../shared/paths";
// import datehourformat from "../../components/external/modules/shared/utils/datehourformat";

export default {
  components: {
    VBtn,
    VCard,
    VCardTitle,
    VCol,
    VContainer,
    VDivider,
    VIcon,
    VRow,
    // VSpacer,
    WCheckbox,
    // WEntityList,
    WNumField,
    WTextarea,
    WTextField,
  },

  data: () => ({
    item: {
      parentOrder: {},
    },
    orderReadPath: path.ORDER,
    showMoreLine: false,
  }),

  created() {
    api
      .get("/purchaseorderitem/read/" + this.$route.params.which)

      .then((res) => {
        if (res.data.status < 400 && res.data.entity !== null) {
          this.item = res.data.entity;
        } else {
          console.log(new Error("Failed loading the order"));
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },

  methods: {
    getDeliveryDate() {
      if (this.item.deliveryHourString) {
        return (
          this.item.deliveryDayString + " @ " + this.item.deliveryHourString
        );
      }
      return this.item.deliveryDayString;
    },
    getManufRef() {
      let elems = [];
      if (this.item.productManufacturerCode) {
        elems.push(this.item.productManufacturerCode);
      }
      if (this.item.productManufacturerReference) {
        elems.push(this.item.productManufacturerReference);
      }
      return elems.join(" - ");
    },
    getProductLabel() {
      let elems = [];
      if (this.item.providerProductLabel) {
        elems.push(this.item.providerProductLabel.trim());
      }
      if (this.item.productBrand) {
        elems.push("(" + this.item.productBrand + ")");
      }
      return elems.join(" ");
    },
    getTitle() {
      return "Line n°" + this.item.num + " (" + this.item.itemReference + ")";
    },
    openOrder() {
      this.$router.push({
        name: path.ORDER,
        params: { which: this.item.parentOrder.orderReference },
      });
    },
  },
};
</script>

<style>
</style>
