<template>
  <v-textarea
    :key="nbChanges"
    v-model="innerValue"
    :label="label"
    :filled="!editable"
    :readonly="!editable"
    :clearable="editable"
    outlined
    dense
    hide-details
    :class="getClasses"
    ref="myTextField"
  />
</template>

<script>
import baseInput from "./WBaseInputMixin"; // this mixin helps handling some common mechanics
import { VTextarea } from "vuetify/lib";

export default {
  name: "w-textarea",

  mixins: [baseInput],

  components: { VTextarea },

  props: {},

  data: () => ({
    // menuOpen: false,
  }),

  computed: {
    getClasses() {
      if (this.text) {
        // thanks to the :key prop, when a value is (asynchronously) provided, the component is re-rendered,
        // and so some computations made without the value are done again
        return "w-text-area mb-5";
      }
      return "w-text-area w-text-area-empty mb-5";
    },
  },

  watch: {
    innerValue(newVal, oldVal) {
      this.refreshComponent(newVal, oldVal);
    },
  },

  methods: {},
};
</script>

<style scoped>
.w-text-area /deep/ .v-input__slot {
  height: 78px;
}
.w-text-area /deep/ .v-text-field__slot {
  padding-top: 8px;
}
.w-text-area /deep/ .v-label {
  font-size: 20px;
}
.w-text-area-empty /deep/ .v-label {
  height: 25px;
  font-size: 16px;
  padding-top: 4px;
  padding-right: 2px;
  font-style: italic;
}
.w-text-area /deep/ .v-input__icon {
  margin-top: 4px;
}
</style>
